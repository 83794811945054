.layout-fullheight {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.layout-content {
    padding: 24px 32px;
    flex: 1;
}

.layout-spacer {
    margin-bottom: 32px;
}
