.qrscanner-video {
    position: absolute;
    object-fit: cover;

    width: 100%;
    height: 100%;
}

.qrscanner-virtual {
    position: absolute;
    visibility: hidden;

    transform: scale(1%);
}
