.orderview-add-button {
    background-color: #b0bc04;
    width: 100%;

    margin-bottom: 25px;
    padding: 24px 24px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 16px;
    text-transform: uppercase;
    color: white;
}

.orderview-finish-button {
    background-color: black;
    width: 100%;

    margin: 25px 0;
    padding: 24px 24px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 16px;
    text-transform: uppercase;
    color: white;
}

.orderview-shops {
    margin: 25px 0;
}

.orderview-shop {
    font-size: 20px;
    text-transform: uppercase;
}

.orderview-shop > hr {
    border: 0;
    border-top: 1px solid black;
}
