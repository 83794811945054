.dialog-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 75%);
    z-index: 1;
}

.dialog-dialog {
    min-width: 50vmin;
    max-width: 80vmin;

    width: auto;
    height: auto;

    background-color: white;
    border-radius: 8px;
    
    display: flex;
    flex-direction: column;
}

.dialog-title {
    padding: 12px 12px;
    padding-bottom: 6px;
    margin-bottom: 6px;

    font-size: 24px;

    border-bottom: 1px solid black;
}

.dialog-buttons {
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 6px 16px;
}

.dialog-button {
    padding: 8px;
    margin: 4px;

    font-size: 16px;

    background-color: white;
    border-radius: 50vmin;

    border: none;
}
