.selecttimeslot-weekday {
    color: #b0bc04;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
}

.selecttimeslot-section {
    padding: 16px 0px;
    color: #b0bc04;
    text-transform: uppercase;
}
.selecttimeslot-padded {
    margin-top: 32px;
}

.selecttimeslot-hr {
    border: 0;
    border-top: 1px solid black;
}

.selecttimeslot-item {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding-top: 4px;
}
.selecttimeslot-item > .selecttimeslot-hr {
    margin: 0;
    margin-bottom: 8px;
}

.selecttimeslot-selected {
    border-radius: 10px 10px 0 0;
    background-color: lightgray;
}

.selecttimeslot-button {
    background-color: #b0bc04;
    width: 100%;

    margin-bottom: 25px;
    margin-top: 32px;
    padding: 24px 24px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 16px;
    text-transform: uppercase;
    color: white;
}

.selecttimeslot-pricing {
    display: flex;
    flex-direction: column;

    align-items: center;

    border: 1px solid black;
    padding: 8px;
}

.selecttimeslot-pricing-price {
    font-size: 24px;
}
