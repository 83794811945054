.loadingspinner-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 75%);
}

.loadingspinner-dialog {
    width: 50vmin;
    height: 50vmin;

    border-radius: 10vmin;
    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingspinner-spinner {
    border: 6px solid #333333;
    border-top: 6px solid #b0bc04;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
