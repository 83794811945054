.tour-container {
    height: 100%;
    background-color: #786c6c;
    position: relative;
}

.tour-swiper {
    height: 100%;
}

.tour-description {
    padding: 24px 24px;

    color: white;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
}
.tour-description > i {
    font-size: 96px;
}

.tour-step {
    padding: 24px 24px;

    color: white;
    font-size: 24px;
    text-transform: uppercase;

    text-align: center;
    white-space: pre-line;
}

.tour-step-note {
    font-size: 20px;
    text-transform: none;
}

.tour-index {
    position: absolute;
    right: 24px;
    bottom: 24px;

    color: white;
    font-size: 20px;
}
