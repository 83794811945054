.home-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.home-button {
    background-color: #b0bc04;
    width: calc(100% - 50px);

    margin: 25px 25px;
    padding: 24px 24px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 28px;
    text-transform: uppercase;
    color: white;
}

.home-tour {
    flex: 1;
}
