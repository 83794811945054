.presetapply-item {
    display: flex;
    flex-direction: column;

    margin-bottom: 24px;
}

.presetapply-item > span.presetapply-item-label {
    font-size: 16px;
    text-transform: uppercase;
}

.presetapply-item > input {
    padding: 4px 12px;

    border: none;
    border-bottom: 1px solid black;
    border-radius: 0 10px 0 0;

    background-color: #b0bc0466;
    font-size: 24px;
}

.presetapply-button {
    background-color: #b0bc04;
    width: 100%;

    padding: 12px 12px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 16px;
    text-transform: uppercase;
    color: white;
}
