.title-h1 {
    color: #b0bc04;
    text-transform: uppercase;
    font-size: 20px;
}

.title-hr {
    border: 0;
    border-top: 1px solid black;
}
