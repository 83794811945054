.orderslist-hr {
    border: 0;
    border-top: 1px solid black;
}

.orderslist-item {
    width: 100%;
    text-align: center;
    font-size: 20px;
}
.orderslist-item > .orderslist-hr {
    margin: 0;
    margin-bottom: 8px;
}
