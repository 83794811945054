.header-container {
    height: 80px;
    background-color: #b0bc04;

    display: flex;
    flex-direction: row;
}

.header-logo {
    position: absolute;
    height: 64px;

    top: 8px;
    right: 32px;

    filter: invert();
}

.header-back-button {
    width: 80px;
    height: 80px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-back-button > i {
    color: white;
    font-size: 40px;
    font-weight: bold;
}

.header-spacer {
    flex: 1;
    flex-grow: 1;
}
