.qrscanner-page-root-container {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.qrscanner-page-container {
    position: relative;
    flex: 1;
}

.qrscanner-page-overlay {
    position: absolute;

    bottom: 0;
    left: 0;
    right: 0;

    padding: 24px;
    padding-bottom: 0;
}

.qrscanner-page-manual {
    padding: 24px;
}

.qrscanner-page-manual-list {
    padding: 16px;
}
