.adminslots-section {
    padding-bottom: 24px;
}

.adminslots-section-header {
    color: #b0bc04;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}

.adminslots-section-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
}

.adminslots-hr {
    border: 0;
    border-top: 1px solid black;
}

.adminslots-item {
    flex: 1;
    font-size: 20px;
    padding: 4px 10px 4px 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adminslots-slot-section {
    padding: 16px 0px;
    color: #b0bc04;
    text-transform: uppercase;
}

.adminslots-slot-section > hr {
    margin: 0;
    margin-bottom: 8px;

    border: 0;
    border-top: 1px dotted black;
}

.adminslots-slot-item {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding-top: 4px;
}
