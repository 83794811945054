.navbar-container {
    height: 80px;

    display: flex;
    flex-direction: row;
}

.navbar-button {
    display: inline-block;
    border: none;
    flex: 1;

    color: white;
    font-size: 16px;
    text-transform: uppercase;
}

.navbar-button-left {
    background-color: #b0bc04;
}

.navbar-button-right {
    background-color: black;
}
