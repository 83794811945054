.presetedit-section {
    padding-bottom: 24px;
}

.presetedit-section-header {
    color: #b0bc04;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}

.presetedit-section-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
}

.presetedit-hr {
    border: 0;
    border-top: 1px solid black;
}

.presetedit-item {
    flex: 1;
    font-size: 20px;
    padding: 4px 10px 4px 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.presetedit-button {
    background-color: #b0bc04;
    width: 100%;

    padding: 12px 12px;
    margin-bottom: 24px;

    border-width: 0;
    border-radius: 100vmin;

    font-size: 16px;
    text-transform: uppercase;
    color: white;
}
